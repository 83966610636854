<template>
  <div class="xiaozi-join">
    <!-- <div class="header">
      <div @click="back" class="back">
        <img :src="require('@/assets/imgs/workbench/icon_arrow-left.svg')"/>
      </div>
      <span class="name">加盟服务</span>
    </div> -->
    <div class="content">
      <div class="panel box-1">
        <div class="join-box">
          <div class="name">小紫平台&emsp;加盟计划
            <span class="line"></span>
          </div>
          <div class="desc">资源共享，合作共赢</div>
          <div v-show="!['1', '2', '3'].includes(entryStatus.enteringAuditStatus) && userInfo.adminFlag" @click="toJoin" class="join">立即入驻</div>
        </div>
        <div class="img-box">
          <img :src="require('@/assets/imgs/workbench/ic_jishuzhichi.svg')"/>
          <img :src="require('@/assets/imgs/workbench/ic_shuzihua.svg')"/>
          <img :src="require('@/assets/imgs/workbench/ic_gongyinglian.svg')"/>
        </div>
      </div>
      <div class="panel join-process">
        <div class="title">入驻流程</div>
        <div class="join-step">
          <div class="step-box">
            <span class="step">1</span>
            <div class="detail">
              <div class="line-1">
                <b>企业认证</b>
                <span>约5分钟</span>
              </div>
              <div class="line-2">上传营业执照等相关资质材料</div>
              <div class="line-3">
                如未认证，可<span @click="toIdentify">前去认证 ></span>
              </div>
            </div>
          </div>
          <img :src="require('@/assets/imgs/workbench/ic_arrow.svg')" class="xz-step-arrow" />
          <div class="step-box">
            <span class="step">2</span>
            <div class="detail">
              <div class="line-1">
                <b>小紫加盟服务商认证</b>
                <span>约20分钟</span>
              </div>
              <div class="line-2">完善企业相关信息，在线制作并上传合作商资质审核表</div>
            </div>
          </div>
          <img :src="require('@/assets/imgs/workbench/ic_arrow.svg')" class="xz-step-arrow" />
          <div class="step-box">
            <span class="step">3</span>
            <div class="detail">
              <div class="line-1">
                <b>平台审核</b>
                <span>约1-3个工作日</span>
              </div>
              <div class="line-2">平台进行资质审核</div>
              <div class="line-3">
                （可同步<span @click="toJoinYixiubao">开通医修保</span>）
              </div>
            </div>
          </div>
        </div>
        <div class="title">资质材料</div>
        <div class="qualification">
          <div class="table">
            <img :src="require('@/assets/imgs/workbench/ic_wendang.svg')" class="horn" />
            <span class="name">服务商资质审核表</span>
            <span class="line"></span>
            <span class="line"></span>
            <span class="line"></span>
            <div class="button">
              <span @click="viewModel" class="scan">预览</span>
              <span @click="downloadModel" class="download">下载</span>
            </div>
          </div>
          <div class="info">
            <div><span style="color: #FF7A75;">* </span>必要的准备材料</div>
            <div class="info-box">
              <div v-for="(item, index) in list" :key="'info-1-'+index" class="item">
                <img :src="item.icon"/>
                <span>{{ item.title }}</span>
              </div>
            </div>
            <div class="tip">注：入驻页面将资料填写完毕，生成审核表，加盖公章上传。也可直接下载模版进行填写后加盖公章上传；</div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="!['1', '2', '3'].includes(entryStatus.enteringAuditStatus) && userInfo.adminFlag" class="footer">
      <div v-if="userInfo.authenticationStatus === '1'" class="tip">提示：加盟服务之前请先完成企业认证，<span @click="toIdentify">前去企业认证</span></div>
      <div @click="toJoin" class="join">立即入驻</div>
    </div>
    <!-- 小紫小程序码 -->
    <el-dialog title="立即入驻" :visible.sync="dialogVisible" width="380px">
      <div class="join-tip">请前往小紫小程序完成加盟入驻流程</div>
      <div class="code-box">
        <img :src="require('@/assets/MINI-code.png')" class="mini-code" />
        <div class="desc">打开微信“扫一扫”功能</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button @click="dialogVisible = false" size="small" type="primary">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { check } from '@/api/company';
export default {
  components: {
  },
  computed: {
  },
  data () {
    return {
      userInfo: {},
      entryStatus: {},
      dialogVisible: false,
      isSupplier: false,
      model: {
        name: '服务商资质审核表',
        url: `${process.env.VUE_APP_HOST}10000000000/eligibilityTable.pdf`,
      },
      list: [
        {
          icon: require('@/assets/imgs/workbench/ic_yingyezhizhao.svg'),
          title: '营业执照复印件',
        },
        {
          icon: require('@/assets/imgs/workbench/ic_jin3nianxiaoshoue.svg'),
          title: '近三年销售额【附合同】',
        },
        {
          icon: require('@/assets/imgs/workbench/ic_shijijingyingren.svg'),
          title: '公司实际经营人',
        },
        {
          icon: require('@/assets/imgs/workbench/ic_gongchengshi.svg'),
          title: '工程师人数及资质证明',
        },
        {
          icon: require('@/assets/imgs/workbench/ic_kaipiaoxinxi.svg'),
          title: '开票信息',
        },
      ],
    };
  },
  mounted () {
    this.userInfo = JSON.parse(this.$local.get('userInfo')) || {};
    this.entryStatus = JSON.parse(this.$local.get('entryStatus')) || {};
  },
  created () {
    this.entryStatus = JSON.parse(localStorage.getItem('entryStatus'));
    this.judgeIsXiaoZi();
  },
  methods: {
    judgeIsXiaoZi () {
      check({operateType: '1'}).then(({body})=>{
        this.isSupplier = (!!body.name) || false;
      });
    },
    back () {
      this.$router.back();
    },
    toJoinYixiubao () {
      this.$router.push({
        name: 'JOIN_YIXIUBAO',
      });
    },
    toIdentify () {
      this.$router.push({
        name: 'IDENTIFICATION',
      });
    },
    viewModel () {
      window.open(this.model.url, '_blank');
    },
    downloadModel () {
      var xhr = new XMLHttpRequest();
      xhr.open('get', this.model.url);
      xhr.responseType = 'blob';
      xhr.onload = () => {
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(xhr.response);
        link.target = '_blank';
        link.download = this.model.name;
        link.click();
        link.remove();
      };
      xhr.send();
    },
    toJoin () {
      // 未认证企业
      if (this.userInfo.authenticationStatus === '1') {
        this.$confirm('企业认证后才能开通小紫加盟服务，是否去认证？', '提示', {
          confirmButtonText: '去认证',
          cancelButtonText: '暂不认证',
          type: 'warning',
        }).then(() => {
          this.$router.push({
            name: 'IDENTIFICATION',
          });
        });
        return;
      }

      // 认证中企业
      if (this.userInfo.authenticationStatus === '2') {
        this.$confirm('企业正在认证中，认证后才能开通小紫加盟服务，请耐心等待审核！', '提示', {
          confirmButtonText: '我知道了',
          showCancelButton: false,
          type: 'warning',
        });
        return;
      }

      if (this.isSupplier) {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'));
        let tenantName = userInfo.departmentName;
        this.$confirm(`【${tenantName}】已是小紫合作供应商，暂时不能开通小紫加盟服务！`, '加盟提示', {
          showCancelButton: false,
          confirmButtonText: '知道了',
          type: 'warning',
        });
        return;
      }

      if (this.userInfo.authenticationStatus === '3') {
        if (['4', null, '3'].includes(this.entryStatus.enteringAuditStatus)) {
          this.$router.push({
            name: 'CorporateCertification',
          });
        } else {
          this.$router.push({
            name: 'CompanyDetail',
          });
        }
        return;
      }
      // this.dialogVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.xiaozi-join {
  .header {
    background-color: #ffffff;
    padding: 10px 5px;
    display: flex;
    align-items: center;
    .back {
      height: 32px;
      padding: 5px 15px;
      border-right: 1px solid #EBEDF0;
      > img {
        width: 20px;
        height: 20px;
      }
    }
    .name {
      padding-left: 15px;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .content {
    padding: 0 0 75px;
    overflow-x: scroll;
    .panel {
      background-color: #ffffff;
      border-radius: 6px;
      margin-bottom: 8px;
      .title {
        font-size: 16px;
        font-weight: bold;
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
    .box-1 {
      padding: 35px;
      display: flex;
      .join-box {
        width: 30%;
        padding-left: 10px;
        display: inline-flex;
        flex-direction: column;
        align-items: baseline;
        justify-content: space-around;
        .name {
          font-style: oblique;
          font-size: 26px;
          font-weight: 600;
          letter-spacing: 5px;
          position: relative;
          .line {
            position: absolute;
            bottom: 3px;
            left: 0;
            width: 256px;
            height: 8px;
            background: linear-gradient(90deg, #1A66FF 0%, #4FA9F3 49%, rgba(135,241,230,0) 100%);
            opacity: 0.8;
            filter: blur(0px);
          }
        }
        .desc {
          font-size: 16px;
          color: #5F6A7A;
        }
      }
      .img-box {
        width: 70%;
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
        img {
          width: 30%;
          margin-left: 3%;
        }
      }
    }
    .join-process {
      padding: 0 20px 20px;
      .join-step {
        width: 100%;
        height: 135px;
        padding: 20px;
        margin-bottom: 10px;
        background: #ECF2FD;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .step-box {
          height: 95px;
          background: #FFFFFF;
          border-radius: 11px;
          padding: 10px 20px;
          display: inline-flex;
          width: 30%;
          min-width: 320px;
          .step {
            flex-shrink: 0;
            font-size: 14px;
            font-weight: normal;
            font-style: oblique;
            color: #FFFFFF;
            width: 20px;
            text-align: center;
            height: 20px;
            line-height: 20px;
            background: #237FFA;
            border-radius: 50%;
            margin-right: 15px;
            margin-top: 2px;
          }
          .detail {
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            .line-1 {
              margin-bottom: 6px;
              display: inline-flex;
              align-items: center;
              >b {
                font-size: 16px;
                font-weight: 500;
                color: #000000;
                margin-right: 20px;
              }
              >span {
                background: #F6F6FB;
                border-radius: 3px;
                font-size: 14px;
                font-weight: 400;
                color: #5F6A7A;
                padding: 2px 10px;
              }
            }
            .line-2, .line-3 {
              font-size: 14px;
              font-weight: 400;
              color: #5F6A7A;
              cursor: pointer;
              >span {
                color: #237FFA;
              }
            }
          }
        }
        .xz-step-arrow {
          width: 26px;
          height: 18px;
          margin: 0 15px;
        }
        @media screen and (max-width: 1439px){
          .xz-step-arrow {
            margin: 0 10px;
          }
        }
      }
      .qualification {
        display: flex;
        .table {
          margin-right: 20px;
          width: 160px;
          height: 200px;
          overflow: hidden;
          background: #FFFFFF;
          box-shadow: 2px 2px 4px 0px rgba(215,219,224,0.7);
          border-radius: 6px;
          border: 1px solid #EBEDF0;
          display: inline-flex;
          flex-direction: column;
          align-items: center;
          flex-shrink: 0;
          position: relative;
          .horn {
            width: 55px;
            height: 56px;
            position: absolute;
            top: -13px;
            left: -13px;
          }
          .name {
            font-size: 16px;
            font-weight: 500;
            color: #1F2733;
            padding-top: 35px;
            padding-bottom: 25px;
          }
          .line {
            width: 125px;
            height: 6px;
            background: #F2F4F7;
            margin-bottom: 8px;
          }
          .button {
            cursor: pointer;
            padding-top: 25px;
            width: 125px;
            display: inline-flex;
            justify-content: space-between;
            .scan, .download {
              padding: 2px 15px;
              border-radius: 4px;
              font-size: 12px;
              font-weight: 400;
            }
            .scan {
              background: #F2F4F7;
              color: #1F2733;
            }
            .download {
              background: #237FFA;
              color: #FFFFFF;
            }
          }
        }
        .info {
          height: 195px;
          font-size: 14px;
          font-weight: 400;
          color: #1F2733;
          position: relative;
          .info-box {
            display: flex;
            flex-wrap: wrap;
            .item {
              width: 270px;
              background: #F5F7FA;
              border-radius: 4px;
              padding: 10px;
              margin-top: 6px;
              margin-right: 30px;
              margin-bottom: 20px;
              >img {
                width: 21px;
                height: 21px;
                margin-right: 8px;
              }
            }
          }
          .tip {
              position: absolute;
              bottom: 0;
              left: 0;
              font-size: 12px;
              font-weight: 400;
              color: #5F6A7A;
          }
        }
      }
    }
  }
  .footer {
    position: fixed;
    background-color: #ffffff;
    bottom: 0;
    right: 0;
    width: calc(100% - 220px);
    padding: 15px 20px;
    text-align: right;
    .tip {
      display: inline-block;
      font-size: 14px;
      color: #606266;
      margin-right: 20px;
      > span {
        color: #237FFA;
        font-weight: bold;
        padding: 0 5px;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  .join {
    display: inline-block;
    background: #237FFA;
    padding: 5px 15px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    cursor: pointer;
  }
  .join-tip {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    color: #1F2733;
    padding-left: 15px;
  }
  .code-box {
    padding: 25px 0;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .mini-code {
      width: 148px;
      height: 148px;
    }
    .desc {
      padding: 15px;
      font-size: 12px;
      font-weight: 400;
      color: #929AA6;
    }
  }
}
</style>
